import { Dialog } from "../../../components/ui";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { NetworkAndToken } from "../../../recoil/mint";
import { ARBITRUM_CFRM, BSC_CFRM } from "../../../utils/constants";
import { StakeState, stakingState } from "../../../recoil/stake";

type Direction = "source" | "destination";

const StakeNetworkSelectDialog = ({
  show,
  onHide,
  direction,
}: {
  show: boolean;
  onHide: () => void;
  direction: Direction;
}) => {
  const [stakeState, setStakeState] = useRecoilState<StakeState>(stakingState);
  const [allNetworks, setAllNetworks] = useState<NetworkAndToken[]>([
    {
      ...ARBITRUM_CFRM,
    },
    {
      ...BSC_CFRM,
    },
  ]);

  const selectNetwork = (networkAndToken: NetworkAndToken) => {
    const sourceNetworkAndToken = networkAndToken;
    const destinationNetworkAndToken = {
      ...(networkAndToken.networkId === allNetworks[0].networkId
        ? ARBITRUM_CFRM
        : BSC_CFRM),
    };

    const feeOnStake =
      sourceNetworkAndToken.networkId === allNetworks[0].networkId
        ? "0.0"
        : "0.3";

    setStakeState({
      ...stakeState,
      sourceNetworkAndToken: sourceNetworkAndToken,
      destinationNetworkAndToken: destinationNetworkAndToken,
      feeOnStake,
      finalStakeAmount: (
        Number(stakeState.amount) -
        Number(stakeState.amount) * (Number(feeOnStake) / 100)
      ).toString(),
    });
    onHide();
  };

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title="Select Network"
      showCloseButton
      size="sm"
    >
      <div
        className="flex !mt-4 justify-evenly overflow-auto xs:mt-2 xs:w-[350px] sm:mt-0 md:mt-0"
        style={{ scrollbarWidth: "none" }}
      >
        {allNetworks
          .filter(
            (network: NetworkAndToken) =>
              network?.networkName === "BSC" ||
              network.networkName === "Arbitrum"
          )
          .map((network: any, index: any) => (
            <div
              className={`flex w-14 cursor-pointer flex-col justify-center items-center hover:bg-backgroundDark py-1`}
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
              key={index}
              onClick={() => selectNetwork(network)}
            >
              <img
                src={network.networkImageUrl}
                alt="network"
                width={24}
                height={24}
              />
              <div className="mt-2 whitespace-nowrap text-center text-[10px] text-white">
                {network.networkName}
              </div>
            </div>
          ))}
      </div>
    </Dialog>
  );
};

export default StakeNetworkSelectDialog;
