import { Dialog } from "../../../components/ui";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { MintState, NetworkAndToken, mintingState } from "../../../recoil/mint";
import {
  ARBITRUM_CFRM,
  ARBITRUM_FRM,
  BSC_CFRM,
  BSC_FRM,
} from "../../../utils/constants";
import { UnWrapState, unWrapingState } from "../../../recoil/unWrap";

type Direction = "source" | "destination";

const UnWrapNetworkSelectDialog = ({
  show,
  onHide,
  direction,
}: {
  show: boolean;
  onHide: () => void;
  direction: Direction;
}) => {
  const [unWrapState, setUnWrapState] =
    useRecoilState<UnWrapState>(unWrapingState);
  const [allNetworks, setAllNetworks] = useState<NetworkAndToken[]>([
    {
      ...ARBITRUM_CFRM,
    },
    {
      ...BSC_CFRM,
    },
  ]);

  useEffect(() => {});

  console.log({ allNetworks });

  const selectNetwork = (networkAndToken: NetworkAndToken) => {
    console.log("here");
    const sourceNetworkAndToken = networkAndToken;
    const destinationNetworkAndToken = {
      ...(networkAndToken.networkId === allNetworks[0].networkId
        ? ARBITRUM_FRM
        : BSC_FRM),
    };

    setUnWrapState({
      ...unWrapState,
      sourceNetworkAndToken: sourceNetworkAndToken,
      destinationNetworkAndToken: destinationNetworkAndToken,
    });
    onHide();
  };

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title="Select Network"
      showCloseButton
      size="sm"
    >
      <div
        className="flex !mt-4 justify-evenly overflow-auto xs:mt-2 xs:w-[350px] sm:mt-0 md:mt-0"
        style={{ scrollbarWidth: "none" }}
      >
        {allNetworks
          .filter(
            (network: NetworkAndToken) =>
              network?.networkName === "BSC" ||
              network.networkName === "Arbitrum"
          )
          .map((network: any, index: any) => (
            <div
              className={`flex w-14 cursor-pointer flex-col justify-center items-center hover:bg-backgroundDark py-1`}
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
              key={index}
              onClick={() => selectNetwork(network)}
            >
              <img
                src={network.networkImageUrl}
                alt="network"
                width={24}
                height={24}
              />
              <div className="mt-2 whitespace-nowrap text-center text-[10px] text-white">
                {network.networkName}
              </div>
            </div>
          ))}
      </div>
    </Dialog>
  );
};

export default UnWrapNetworkSelectDialog;
